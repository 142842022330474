.speaker{
    margin: 20px;
    box-shadow: 0px 5px 20px -16px rgba(0, 0, 0, 0.75);
    padding: 50px;
    margin-left: 50px !important;
    margin-right: 50px;
    width: inherit;
    text-align: justify;
}

.heading {
    font-weight: bold;
    font-size: x-large; 
    color: brown;
    margin-bottom: 40px;
}

.matter p{
    line-height: 2;
    color: #828C9E;
    font-size: 15px;
    margin-bottom: 0;
    margin-left: 20px;
}

figure{
    text-align: center ;
}

.top_box{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: row;
}

.outer_box{
    box-shadow: 1px 5px 20px -16px rgba(0, 0, 0, 0.75);
    border-radius:  40px;
    padding:  15px;
    margin: 20px;
}

.inner_box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 280px;
}

