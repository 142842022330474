.icac3n{
    margin: 20px;
    box-shadow: 0px 5px 20px -16px rgba(0, 0, 0, 0.75);
    padding: 60px;
    margin-left: 50px !important;
    margin-right: 50px;
    width: inherit;
    text-align: justify;

}

.heading p{
    font-weight: bold;
    font-size: large;
    color: brown;
}

.matter p{
    line-height: 2;
    color: rgb(87, 86, 86);
    font-weight: 500;
    font-size: 17px;
}