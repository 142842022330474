.callofpaper{
    margin: 20px;
    box-shadow: 0px 5px 20px -16px rgba(0, 0, 0, 0.75);
    padding: 50px;
    margin-left: 50px !important;
    margin-right: 50px;
    width: inherit;
    text-align: justify;
}

.matter h1{
    margin-top: 20px;
    margin-left: 20px;
    font-size: 20px;
    color: brown;
}

.matter p{
    line-height: 2;
    color: #828C9E;
    font-size: 15px;
    margin-bottom: 0;
    margin-left: 20px;
}

.heading p{
    font-weight: bold;
    font-size: large;
    color: brown ;
    text-align: center;
    text-transform: uppercase;
}

.track-details{
    margin-left: 15px; 
}

.track-details ul li{
    color: #788191;
    margin-right: 7px;
    font-weight: 550;
}

