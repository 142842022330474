.body{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    /* height: 100vh; */
}

.body h3{
    font-weight: 600;
}

.left_section{
    background-color: white;
    flex: 0.67;
}

.right_section{
    
    flex: 0.33;
}

.social{
    margin: 20px;
    box-shadow: 0px 5px 20px -16px rgba(0, 0, 0, 0.75);
    padding: 20px;
    margin-right: 50px;
    margin-left: 20px;

}

.follow{
    padding: 20px;
    margin-left: 17px;
}

.icon{
    /* padding: 0px 10px; */
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-bottom: 30px;
}

.iconDetails{
    /* padding: 5px; */
    color: #0066bf;
    
}

.iconDetails:hover{
    color: greenyellow;
}

.linkDetails
{
    background-color: #efefef;
    padding: 10px;
    border-radius: 5px;
    /* margin-bottom: 30px; */
}
.linkDetails:hover{
    background-color: grey;
    color: greenyellow;
}

.fb_frame{
    margin: 20px;
    box-shadow: 0px 5px 20px -16px rgba(0, 0, 0, 0.75);
    padding: 20px;
    margin-top: 50px;
    margin-right: 50px;
}

.twitt_frame{
    margin: 20px;
    box-shadow: 0px 5px 20px -16px rgba(0, 0, 0, 0.75);
    padding: 20px;
    margin-top: 50px;
    margin-right: 50px;
}

.twitt_frame iframe{
    padding: 20px !important;
}

.brochure{
    margin-top: 50px;
    margin-left: 20px;
    box-shadow: 0px 5px 20px -16px rgba(0, 0, 0, 0.75);

}

.view{
    margin-left: 40px;
    text-transform: uppercase;
    text-decoration: none;
    border-radius: 20px;
    color: white !important;
    background-color: rgb(15, 80, 77);;
    padding: 8px;
    width: fit-content;
    margin-bottom: 10px;
}

.view a{
    text-decoration: none;
    color: white;
    font-weight: bold;
}