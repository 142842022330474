.mdl {
    font-size: 12px;
    padding: 0px;
  }

.mdl > .header_popup {
    width: 100%;
    border-bottom: 1px solid gray;
    font-size: 22px;
    font-weight: 500;
    text-align: center;
    padding: 10px;
    color: white;
    background-color: black;
    font-family: 'Noto Sans', sans-serif;

  }

.mdl > .content_popup {
    width: 100%;
    font-size: 15px;
    font-weight: 500;
    color: black;
    background-color: #ffc200;
    padding: 15px 15px;
    font-family: 'Noto Sans', sans-serif;
    margin: 0px;
  }

.mdl > .close {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 2px 5px;
    line-height: 20px;
    right: -10px;
    top: -10px;
    font-size: 24px;
    background: #ffffff;
    border-radius: 18px;
    border: 1px solid #cfcece;
}

.popup_btn{
    outline: none;
    border-radius: 10px;
    background-color: #ffffff;
    text-decoration: none;
    border: none;
}

.popup-content{
    padding: 0px !important;
}