
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap');
*{
  margin: 0;
  padding: 0;
}
h3{
  
  font-family: 'Noto Sans', sans-serif;

}
h1{
  font-weight: 750;

  font-family: 'Noto Sans', sans-serif;
}