.header{
    margin: 50px;
    margin-top: 20px;
    margin-bottom: 30px;
}
.logo{
    margin: 10px;
}
.title{
    padding: 5px;   
}

.title a{
    text-decoration: none;
    color: black;
}

.title a:hover{
    
    color: #09a16f;
}

.title h1{
    margin-left: 10px;
    font-size: 23px;
    font-weight: bold;
}

.head{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.date p{
    display: flex;
    text-align: center;
    color: grey;
    /* justify-content: space-between; */
    /* padding: 10px; */
}

.recNum p{
    font-weight: bold;
    color: grey;
    display: flex;
    text-align: center;
}

/* Blend Mode CSS */
.animate-charcter
{
   text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #231557 0%,
    #44107a 29%,
    #ff1361 67%,
    #fff800 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  /* text-fill-color: transparent; */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 4s linear infinite;
  display: inline-block;
      /* font-size: 190px; */
}

.animate-charcter2
{
   text-transform: uppercase;
   background-image: linear-gradient( 
       -225deg, 
       #153d57 0%, 
       #10737a 29%, 
       #13dcff 67%, 
       #4800ff 100% 
    );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  /* text-fill-color: transparent; */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 4s linear infinite;
  display: inline-block;
      /* font-size: 190px; */
}

@keyframes textclip {
    to {
      background-position: 200% center;
    }
  } 

/* Text Pop Out */

.pop_a{
    color: #ff1361 !important;
    text-decoration: none;
}

.pop_a:hover{
  color: #44107a !important;
}

.line h2 {
    text-align: center;
    padding: 16px;
    margin: 0;
    font-size: 20px !important;
  }

.line {
    width: 100%;
    height: 4rem;
    overflow: hidden;
    /* border: 1px solid black; */
    padding: 0;
    margin-bottom: 16px;
  }
  
  /* subtle zoom to attention and then back */
  .pop-outin {
    animation: 2s anim-popoutin ease infinite;
  }
  
  @keyframes anim-popoutin {
    0% {
      color: black;
      transform: scale(0.75);
      opacity: 0;
      text-shadow: 0 0 0 rgba(0, 0, 0, 0);
    }
    25% {
      color: red !important;
      transform: scale(1.5);
      opacity: 1;
      text-shadow: 3px 10px 5px rgba(0, 0, 0, 0.5);
    }
    50% {
      color: black;
      transform: scale(1);
      opacity: 1;
      text-shadow: 1px 0 0 rgba(0, 0, 0, 0);
    }
    100% {
      /* animate nothing to add pause at the end of animation */
      transform: scale(1);
      opacity: 1;
      text-shadow: 1px 0 0 rgba(0, 0, 0, 0);
    }
  }



  /* Inauguration CSS */
  .inog{
    width: 100%;
    font-size: 20px !important;
    margin-bottom: 20px;
  }
  .title-word {
    animation: color-animation 4s linear infinite;
  }
  
  .title-word-1 {
    --color-1: #44107a;
    --color-2: #ff1361;
    --color-3: #09a16f;

  }
  
  .title-word-2 {
    --color-1: #ff1361;
    --color-2: #09a16f;
    --color-3: #44107a;
  }
  
  .title-word-3 {
    --color-1: #09a16f;
    --color-2: #ff1361;
    --color-3: #44107a;
  }
  
  .title-word-4 {
    --color-1: #3D8DAE;
    --color-2: #DF8453;
    --color-3: #E4A9A8;
  }
  
  @keyframes color-animation {
    0%    {color: var(--color-1)}
    32%   {color: var(--color-1)}
    33%   {color: var(--color-2)}
    65%   {color: var(--color-2)}
    66%   {color: var(--color-3)}
    99%   {color: var(--color-3)}
    100%  {color: var(--color-1)}
  }
  
  
  .title_d {
    /* display: flex; */
    /* justify-content: space-evenly; */
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-weight: bolder;
    font-size: 20px;
  }

  .inog a{
    text-decoration: none;
  }