.sub{
    margin: 20px;
    box-shadow: 0px 5px 20px -16px rgba(0, 0, 0, 0.75);
    padding: 60px;
    margin-left: 50px !important;
    margin-right: 50px;
    width: inherit;
    text-align: justify;
}

.heading p{
    font-weight: bold;
    font-size: large;
    color: brown ;
    text-align: center;
}

.matter p{
    line-height: 2;
    color: rgb(87, 86, 86);
    font-weight: 500;
    font-size: 17px;
}

.has-text-align-justify{
    text-align: justify;
}

.btn_upper{
    margin: 35px 0px;
    width: max-content;
    margin-left: 1em !important;
}

.anchhor{
    color: white;
    background-color: rgb(15, 80, 77);
    cursor: pointer;
    border-radius: 20px;
    padding: 10px;
    text-decoration: none;
    outline: none;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
}

.anchhor:hover{
    color: white;
}

@media only screen and (max-width: 700px) {
    .sub{
        width: 100%;
        padding: 10px;
    }
    .form-container, .get-in-touch{
        padding-left:  0px;
    }

    .btn_upper{
        width: 100% !important;
    }

    .anchhor {
        text-overflow: clip;
        border-radius: 10px;
        padding: 2px;
        width: max-content;

    }
  }