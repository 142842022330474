.about{
    margin: 20px;
    box-shadow: 0px 5px 20px -16px rgba(0, 0, 0, 0.75);
    padding: 50px;
    margin-left: 50px !important;
    margin-right: 50px;
    width: inherit;
    text-align: justify;
}

.heading p{
    font-weight: bold;
    font-size: large;
    color: brown ;
    text-align: center;
    text-transform: uppercase;
}

.matter p{
    line-height: 2;
    color: #828C9E;
    font-weight: 500;
    font-size: 15px;
}
.gallery{
    width: inherit;
}

.lg img{
    margin-left: auto;
    margin-right: auto;
}

@media only screen and (max-width: 700px) {
    .about{
        width: 100%;
        padding: 10px;
    }
    .form-container, .get-in-touch{
        padding-left:  0px;
    }

    .btn_upper{
        width: 100% !important;
    }

    .anchhor {
        text-overflow: clip;
        border-radius: 10px;
        padding: 2px;
        width: max-content;

    }
  }